<!-- Name -->
<script lang="ts">
export default {
  name: 'app-module-seo'
}
</script>

<script lang="ts" setup>

const { $api } = useNuxtApp();

const props = defineProps({
  slug:{
    type:String,
    required:true
  }
});


//Cargamos filtros iniciales
const { data, error } = await $api.bloqueSeo.show(props.slug);



if (error.value) {
  throw createError({ statusCode: 500, statusMessage: error.value?.data?.message || error.value.message })
}

const contenido = data.value.payload.contenido || false;


</script>

<template lang="pug">
.container(v-if="contenido", v-html="contenido")
   

</template>

<style lang="scss">
@import 'index';
</style>